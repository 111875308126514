import {
  companyInfoQueryResponse,
  divisionQueryResponsePerson,
} from "@/lib/sanityDangerousTypes";
import React, { useState } from "react";
import Image from "next/image";
import { getNavigationFromCompany } from "@/lib/constants";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useReCaptcha } from "next-recaptcha-v3";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const formSchema = z.object({
  content: z.string().optional(),
  email: z.string().email(),
  name: z.string().min(2, {
    message: "Name is required",
  }),
  page: z.string().optional(),
  person: z.string().optional(),
  phone: z.string().optional(),
});

export default function ContactForm({
  children,
  companyInfo,
  pageName,
  people,
}: {
  children: React.ReactNode;
  companyInfo: companyInfoQueryResponse;
  pageName: string;
  people: divisionQueryResponsePerson[] | undefined;
}) {
  const [submitting, setSubmitting] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const { logoLight, name } = companyInfo || {};
  const { contact } = getNavigationFromCompany({ companyInfo });

  const { executeRecaptcha } = useReCaptcha();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      content: "",
      email: "",
      name: "",
      page: pageName,
      person: "",
      phone: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const token = await executeRecaptcha("contact_form_submit");

    fetch("/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...values, token }),
    })
      .then((response) => {
        if (response.ok) {
          setSuccessMsg("Message sent!");
        } else {
          setErrorMsg("Error sending message");
        }
        setSubmitting(false);
      })
      .catch((error) => {
        setErrorMsg("Error sending message");
        console.error("Error:", error);
        setSubmitting(false);
      });
  }

  return (
    <div className="relative px-6 py-24 bg-white isolate sm:py-28 lg:px-8">
      <div className="max-w-xl mx-auto lg:max-w-5xl">
        <div className="flex flex-col gap-16 sm:gap-y-20 lg:flex-row lg:items-start">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="lg:flex-auto"
            >
              {children}

              <div className="mt-8">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="sr-only">Full Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Name *" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="sr-only">Email</FormLabel>
                      <FormControl>
                        <Input placeholder="Email *" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="sr-only">Phone</FormLabel>
                      <FormControl>
                        <Input placeholder="Phone" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="person"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="sr-only">Your Agent</FormLabel>
                      <FormControl>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Your Agent (Optional)" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="No Agent">
                              I&apos;m not working with an agent
                            </SelectItem>
                            {people?.map((person) => {
                              return (
                                <SelectItem
                                  key={person._id}
                                  value={person.name}
                                >
                                  {person.name}
                                </SelectItem>
                              );
                            })}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="content"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="sr-only">
                        Questions or Comments?
                      </FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="Question or Comments"
                          cols={12}
                          rows={7}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <input type="hidden" {...form.register("page")} />
              </div>
              <div className="mt-10">
                <Button
                  type="submit"
                  className="block w-full font-display font-bold rounded-md bg-blue px-3.5 py-2.5 text-center text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue"
                  disabled={submitting}
                >
                  Let&apos;s talk
                </Button>
                {errorMsg && (
                  <div className="items-center justify-center px-3 py-1 mt-3 text-center text-red-600 bg-red-200 border rounded-sm">
                    {errorMsg}
                  </div>
                )}
                {successMsg && (
                  <div className="items-center justify-center px-3 py-1 mt-3 text-center text-green-600 bg-green-200 border rounded-sm">
                    {successMsg}
                  </div>
                )}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-500">
                By submitting this form, I agree to the{" "}
                <Link href="/privacy" className="font-semibold text-blue">
                  privacy&nbsp;policy
                </Link>
                .
              </p>
            </form>
          </Form>
          <div className="sticky p-12 border border-l-4 bg-blue lg:mt-6 lg:w-80 lg:flex-none border-slate-300 border-l-blue">
            <Image
              alt={`${name} logo`}
              className="w-full h-auto"
              height={540}
              layout="responsive"
              src={logoLight || ""}
              width={720}
            />
            <div>
              <ul role="list" className="mt-6 space-y-4">
                {contact.map((item) => (
                  <li key={item.href}>
                    <Link
                      href={item.href}
                      className="flex flex-row text-lg leading-6 no-underline cursor-pointer"
                    >
                      <FontAwesomeIcon
                        aria-hidden="true"
                        className="w-4 h-4 pt-1 mr-2 text-white"
                        icon={item.iconDef}
                      />
                      <div className="text-white font-extralight">
                        {item.label.map((line) => (
                          <div key={line}>{line}</div>
                        ))}
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
